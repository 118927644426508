import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter,  faInstagram } from "@fortawesome/free-brands-svg-icons"

export const BlogPostTemplate = ({
  content,
  categories,
  tags,
  title,
  date,
  author,
}) => {
  return (
    <section className="section">
       <div className="content-wrapper title-page">
        <h1 className="has-text-weight-bold is-size-2" dangerouslySetInnerHTML={{ __html: title }} />
      </div>
     {/* <div className="container">
        <div className="">

            <div dangerouslySetInnerHTML={{ __html: content }} />
            <div style={{ marginTop: `4rem` }}>
              <p>
                {date} - posted by{' '}
                <Link to={`/author/${author.slug}`}>{author.name}</Link>
              </p>
              {categories && categories.length ? (
                <div>
                  <h4>Categories</h4>
                  <ul className="taglist">
                    {categories.map(category => (
                      <li key={`${category.slug}cat`}>
                        <Link to={`/categories/${category.slug}/`}>
                          {category.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              {tags && tags.length ? (
                <div>
                  <h4>Tags</h4>
                  <ul className="taglist">
                    {tags.map(tag => (
                      <li key={`${tag.slug}tag`}>
                        <Link to={`/tags/${tag.slug}/`}>{tag.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>

        </div>
      </div> */}

<div className="container contact-page post">
          <div className="container--inner">
          
            <div className="split-contact">
                <div className="content-wrapper">
                    <div className="content-block">
                        
                    </div>

                    <div className="form">
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                      <div>
                        <p>
                          {date} - posté par{' '}
                          <Link to={`/author/${author.slug}`}>{author.name}</Link>
                        </p>
                        {categories && categories.length ? (
                          <div>
                            <h4>Categories</h4>
                            <ul className="taglist">
                              {categories.map(category => (
                                <li key={`${category.slug}cat`}>
                                  <Link to={`/categories/${category.slug}/`}>
                                    {category.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : null}
                        {tags && tags.length ? (
                          <div>
                            <h4>Tags</h4>
                            <ul className="taglist">
                              {tags.map(tag => (
                                <li key={`${tag.slug}tag`}>
                                  <Link to={`/tags/${tag.slug}/`}>{tag.name}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : null}
                        </div>
                    </div>

                    <div className="content">
                        <h2></h2>
                        <p></p>
                    </div>
                </div>
                <div className="right-aside">
                    <div className="content">
                    <h2>Suivez-nous</h2>

                        <div className="social">
                        <ul className="">
                            <li>
                                <a href="https://www.facebook.com/AssociationJAIDE/"><FontAwesomeIcon size="2x" icon={faFacebook} /><span>Facebook</span></a>
                            </li>
                            <li>
                                <a href=""><FontAwesomeIcon size="2x" icon={faTwitter} /><span>Twitter</span></a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/p/BoW2DNhHGyq/"><FontAwesomeIcon size="2x" icon={faInstagram} /><span>Instagram</span></a>
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>
            
            </div>

          </div>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
}

const BlogPost = ({ data }) => {
  const { wordpressPost: post } = data

  return (
    <Layout>
      <Helmet title={`${post.title} | Blog`} />
      <BlogPostTemplate
        content={post.content}
        categories={post.categories}
        tags={post.tags}
        title={post.title}
        date={post.date}
        author={post.author}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query BlogPostByID($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      date(formatString: "MMMM DD, YYYY")
      categories {
        name
        slug
      }
      # tags {
      #   name
      #   slug
      # }
      author {
        name
        slug
      }
    }
  }
`
